export const API = {
	insecticides: {
    create: `/insecticide`,
    edit: `/insecticide/`,
    delete: `/insecticide/`,
    list: `/insecticide`,
  },
	irrigations: {
    create: `/irrigation`,
    edit: `/irrigation/`,
    delete: `/irrigation/`,
    list: `/irrigation`,
  },
	plants: {
    create: `/plant`,
    edit: `/plant/`,
    delete: `/plant/`,
    list: `/plant`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};

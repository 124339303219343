import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  plants,
  plantsForm,
  irrigations,
  irrigationsForm,
  insecticides,
  insecticidesForm,
} from "./Reducers";

export default combineReducers({
  insecticides,
  insecticidesForm,
  irrigations,
  irrigationsForm,
  plants,
  plantsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});

/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* INSECTICIDE */
export const SET_INSECTICIDE_LIST = "SET_INSECTICIDE_LIST";
export const ADD_NEW_INSECTICIDE = "ADD_NEW_INSECTICIDE";
export const UPDATE_INSECTICIDE = "UPDATE_INSECTICIDE";
export const DELETE_INSECTICIDE = "DELETE_INSECTICIDE";
export const OPEN_INSECTICIDE_FORM = "OPEN_INSECTICIDE_FORM";
export const CLOSE_INSECTICIDE_FORM = "CLOSE_INSECTICIDE_FORM";
export const EDIT_SELECTED_INSECTICIDE = "EDIT_SELECTED_INSECTICIDE";
export const INSECTICIDE_FORM_TOOGLE_LOADING = "INSECTICIDE_FORM_TOOGLE_LOADING";
/* <---- INSECTICIDE ----> */

/* IRRIGATION */
export const SET_IRRIGATION_LIST = "SET_IRRIGATION_LIST";
export const ADD_NEW_IRRIGATION = "ADD_NEW_IRRIGATION";
export const UPDATE_IRRIGATION = "UPDATE_IRRIGATION";
export const DELETE_IRRIGATION = "DELETE_IRRIGATION";
export const OPEN_IRRIGATION_FORM = "OPEN_IRRIGATION_FORM";
export const CLOSE_IRRIGATION_FORM = "CLOSE_IRRIGATION_FORM";
export const EDIT_SELECTED_IRRIGATION = "EDIT_SELECTED_IRRIGATION";
export const IRRIGATION_FORM_TOOGLE_LOADING = "IRRIGATION_FORM_TOOGLE_LOADING";
/* <---- IRRIGATION ----> */

/* PLANT */
export const SET_PLANT_LIST = "SET_PLANT_LIST";
export const ADD_NEW_PLANT = "ADD_NEW_PLANT";
export const UPDATE_PLANT = "UPDATE_PLANT";
export const DELETE_PLANT = "DELETE_PLANT";
export const OPEN_PLANT_FORM = "OPEN_PLANT_FORM";
export const CLOSE_PLANT_FORM = "CLOSE_PLANT_FORM";
export const EDIT_SELECTED_PLANT = "EDIT_SELECTED_PLANT";
export const PLANT_FORM_TOOGLE_LOADING = "PLANT_FORM_TOOGLE_LOADING";
/* <---- PLANT ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */

import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_INSECTICIDE,
    DELETE_INSECTICIDE,
    INSECTICIDE_FORM_TOOGLE_LOADING,
    SET_INSECTICIDE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_INSECTICIDE,
} from "../constants";
import { formatInsecticides } from "./settingsActionsUtils";

/* INSECTICIDE LIST */
export const fetchInsecticides = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.insecticides.list)
            .then((response) => {
                const insecticides = formatInsecticides(response.data);
                dispatch({
                    type: SET_INSECTICIDE_LIST,
                    payload: keyBy(insecticides, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// INSECTICIDES
export const createInsecticide = (newInsecticide) => {
    return async (dispatch) => {
        dispatch({ type: INSECTICIDE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.insecticides.create, newInsecticide)
            .then((response) => {
                const insecticide = formatInsecticides(response.data);
                dispatch({ type: ADD_NEW_INSECTICIDE, payload: insecticide });
                dispatch({ type: INSECTICIDE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Insecticida creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INSECTICIDE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateInsecticide = (updatedInsecticide) => {
    return async (dispatch) => {
        dispatch({ type: INSECTICIDE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.insecticides.edit}${updatedInsecticide && updatedInsecticide._id}`, updatedInsecticide)
            .then((response) => {
                const insecticide = formatInsecticides(response.data);
                dispatch({ type: UPDATE_INSECTICIDE, payload: insecticide });
                dispatch({ type: INSECTICIDE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Insecticida actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INSECTICIDE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteInsecticides = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: INSECTICIDE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.insecticides.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_INSECTICIDE, payload: Ids });
                dispatch({ type: INSECTICIDE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Insecticida eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INSECTICIDE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  SET_USER_LIST,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_ALERT_LIST,
  ADD_NEW_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
  THEME,
  ADD_NEW_PLANT,
  SET_PLANT_LIST,
  UPDATE_PLANT,
  DELETE_PLANT,
  OPEN_PLANT_FORM,
  CLOSE_PLANT_FORM,
  EDIT_SELECTED_PLANT,
  PLANT_FORM_TOOGLE_LOADING,
  ADD_NEW_IRRIGATION,
  SET_IRRIGATION_LIST,
  UPDATE_IRRIGATION,
  DELETE_IRRIGATION,
  OPEN_IRRIGATION_FORM,
  CLOSE_IRRIGATION_FORM,
  EDIT_SELECTED_IRRIGATION,
  IRRIGATION_FORM_TOOGLE_LOADING,
  ADD_NEW_INSECTICIDE,
  SET_INSECTICIDE_LIST,
  UPDATE_INSECTICIDE,
  DELETE_INSECTICIDE,
  OPEN_INSECTICIDE_FORM,
  CLOSE_INSECTICIDE_FORM,
  EDIT_SELECTED_INSECTICIDE,
  INSECTICIDE_FORM_TOOGLE_LOADING,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  false
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PLANTS
export const plants = handleActions(
    {
        [SET_PLANT_LIST]: (state, action) => action.payload,
        [ADD_NEW_PLANT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_PLANT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_PLANT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const plantsForm = handleActions(
    {
        [OPEN_PLANT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_PLANT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_PLANT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [PLANT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// IRRIGATIONS
export const irrigations = handleActions(
    {
        [SET_IRRIGATION_LIST]: (state, action) => action.payload,
        [ADD_NEW_IRRIGATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_IRRIGATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_IRRIGATION]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const irrigationsForm = handleActions(
    {
        [OPEN_IRRIGATION_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_IRRIGATION_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_IRRIGATION]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [IRRIGATION_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// INSECTICIDES
export const insecticides = handleActions(
    {
        [SET_INSECTICIDE_LIST]: (state, action) => action.payload,
        [ADD_NEW_INSECTICIDE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_INSECTICIDE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_INSECTICIDE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const insecticidesForm = handleActions(
    {
        [OPEN_INSECTICIDE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_INSECTICIDE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_INSECTICIDE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [INSECTICIDE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

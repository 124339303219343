import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PLANT,
    DELETE_PLANT,
    PLANT_FORM_TOOGLE_LOADING,
    SET_PLANT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PLANT,
} from "../constants";
import { formatPlants } from "./settingsActionsUtils";

/* PLANT LIST */
export const fetchPlants = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.plants.list)
            .then((response) => {
                const plants = formatPlants(response.data);
                dispatch({
                    type: SET_PLANT_LIST,
                    payload: keyBy(plants, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PLANTS
export const createPlant = (newPlant) => {
    return async (dispatch) => {
        dispatch({ type: PLANT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.plants.create, newPlant)
            .then((response) => {
                const plant = formatPlants(response.data);
                dispatch({ type: ADD_NEW_PLANT, payload: plant });
                dispatch({ type: PLANT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Planta creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLANT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updatePlant = (updatedPlant) => {
    return async (dispatch) => {
        dispatch({ type: PLANT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.plants.edit}${updatedPlant && updatedPlant._id}`, updatedPlant)
            .then((response) => {
                const plant = formatPlants(response.data);
                dispatch({ type: UPDATE_PLANT, payload: plant });
                dispatch({ type: PLANT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Planta actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLANT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const getPlant = async (_id) => {
    const response = await axios
        .get(`${API.plants.edit}${_id}`)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err;
        });
    return response;
};

export const deletePlants = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PLANT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.plants.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PLANT, payload: Ids });
                dispatch({ type: PLANT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Planta eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLANT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_IRRIGATION,
    DELETE_IRRIGATION,
    IRRIGATION_FORM_TOOGLE_LOADING,
    SET_IRRIGATION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_IRRIGATION,
} from "../constants";
import { formatIrrigations } from "./settingsActionsUtils";

/* IRRIGATION LIST */
export const fetchIrrigations = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.irrigations.list)
            .then((response) => {
                const irrigations = formatIrrigations(response.data);
                dispatch({
                    type: SET_IRRIGATION_LIST,
                    payload: keyBy(irrigations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// IRRIGATIONS
export const createIrrigation = (newIrrigation) => {
    return async (dispatch) => {
        dispatch({ type: IRRIGATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.irrigations.create, newIrrigation)
            .then((response) => {
                const irrigation = formatIrrigations(response.data);
                dispatch({ type: ADD_NEW_IRRIGATION, payload: irrigation });
                dispatch({ type: IRRIGATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Riego creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: IRRIGATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateIrrigation = (updatedIrrigation) => {
    return async (dispatch) => {
        dispatch({ type: IRRIGATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.irrigations.edit}${updatedIrrigation && updatedIrrigation._id}`, updatedIrrigation)
            .then((response) => {
                const irrigation = formatIrrigations(response.data);
                dispatch({ type: UPDATE_IRRIGATION, payload: irrigation });
                dispatch({ type: IRRIGATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Riego actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: IRRIGATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteIrrigations = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: IRRIGATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.irrigations.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_IRRIGATION, payload: Ids });
                dispatch({ type: IRRIGATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Riego eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: IRRIGATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

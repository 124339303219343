import moment from "moment";
import { isArray, isObject } from "lodash";

export const formatInsecticides = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      date: data.date && moment(data.date),
      seedtime: data.seedtime && moment(data.seedtime),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      date: data.date && moment(data.date),
      seedtime: data.seedtime && moment(data.seedtime),
    };
  }
};
export const formatIrrigations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      date: data.date && moment(data.date),
    };
  }
};
export const formatPlants = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      seedtime: data.seedtime && moment(data.seedtime),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      seedtime: data.seedtime && moment(data.seedtime),
    };
  }
};
export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname}`,
      value: data._id
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};
